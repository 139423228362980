
















































import { Component, Vue } from 'vue-property-decorator';
import { agentModule } from '@/store/modules/agent';
import { User } from '@/interfaces/user';
import FormSecondStep from './FormSecondStep.vue';

@Component({
    components: { FormSecondStep }
})
export default class Home extends Vue {
    step = 2;

    get tokenData() {
        return agentModule.tokenData;
    }

    form: {
        token?: string;
        email?: string;
        training?: string;
        document?: File;
        filename?: string;
    } = {};

    mounted() {
        this.form = {
            token: this.$route.params.id,
            email: this.tokenData?.email
        };
    }
}
